@tailwind base;
@tailwind components;
@tailwind utilities;

.btn{
  @apply  p-2 bg-blue-900 text-white rounded-3xl cursor-pointer
}
.font-control{
  @apply bg-white p-1 rounded text-sm
}
.light-mode{
  @apply bg-blue-700 text-black
}
.dark-mode{
  @apply bg-black text-white
}

input[type="text"]:focus ~ .input-text{
  @apply text-blue-600  transform -translate-y-9 -translate-x-4 scale-75;
}

