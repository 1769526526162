.App {
  text-align: center;
  margin: 0;
  padding: 0;
  overflow-x: hidden;

}
body {
  font-family: 'Roboto', sans-serif; /* or any font you prefer */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.state-change{
  transition: opacity 0.5s ease-in-out;
  opacity: 0.8;
}
.hide-scrollbar {
  scrollbar-width: none; 
  -ms-overflow-style: none; 

  &::-webkit-scrollbar {
    display: none;
  }
}

.custom-spinner .ant-spin-dot i {
  background-color: white !important; /* Change the color here */
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  box-shadow: 0 0 0px 1000px white inset;
  -webkit-text-fill-color: #000; /* Text color */
}

ul {
  z-index: 9999;
  pointer-events: auto;
}


.package-card {
  padding: 20px;
  background-color: #ffffff;
  border: 2px solid #ddd;
  border-radius: 8px;
  width: 420px; 
  height: 600px; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  margin: 0; 
}



.package-container {
  display: flex;
  justify-content: center; 
  align-items: center; 
  gap: 20px; 
  flex-wrap: wrap; 
  margin-top: 20px;
  margin-bottom: 20px;
}


.package-card .price-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0;
}


.package-card .price-container .price {
  font-size: 1.3rem;
  color: #000;
  margin-top: -15px;
}

.features-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  text-align: left;
}

.features-list li {
  color: #050505;
  font-size: 1rem;
  text-align: left;
  position: relative;
  padding-left: 25px; /* Adjusts spacing between bullet and text */
}

.features-list li::before {
  content: "●";
  color: #424242;
  position: absolute;
  left: 5px; /* Adjusts bullet position */
  font-size: 1.2rem;
}


.buttons-container {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 20px;
}


.get-in-touch-btn,
.payment-btn {
  background-color: #007bff;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 48%; 
  text-align: center;
  margin-bottom:6px;
  margin-top: -10px;
  
}

.get-in-touch-btn:hover,
.payment-btn:hover {
  background-color: #0056b3;
  transition: background-color 0.3s ease;
}
.package-card h3{
  font-size: 1.8em;
  margin-top: -20px;
}

/* Responsive Design: Stacking cards on smaller screens */
@media (max-width: 768px) {
  .package-container {
    flex-direction: column; 
    align-items: center; 
  }

  .package-card {
    width: 100%; 
    margin-bottom: 20px;
  }

  .features-list {
    flex-direction: column; 
    align-items: flex-start;
  }
}

.package-card {
  position: relative;
}

.annual-offer {
  position: absolute;
  bottom: 0px; 
  right: 10px;  
  text-align: right;
  font-size:15px;
  font-weight: bold;
  color: #7e7b7b;
  padding: 5px 10px;
  border-radius: 5px; 
 
  font-style: italic;
  margin-bottom: 20px;
}

.package_h1{
  text-align: center;
  color: white;
  background-color: #007BFF;
  height: 1.5em;
  width: 100%;
  


}
.header{
  font-size: 2.2em;
}


.payment-page {
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; 
  height: 100vh;
  box-sizing: border-box;
  padding-top: 20px; 
 
}


.blue-heading {
  background-color: #007BFF; 
  color: white;
  text-align: center;
  width: 100%;
  padding: 15px 0; 
  margin-bottom: 20px;
}

.blue-heading h1 {
  font-size: 24px; 
  margin: 0;
}


.heading {
  /* background-color: #000000;  */
  color: black;
  text-align: center;
  padding: 12px 0; 
  width: 100%;
  margin-bottom: 20px;
}

.heading h3 {
  font-size: 20px; 
  margin: 0;
}


.payment-details {
  background-color: white; 
  width: 90%; 
  max-width: 700px; 
  padding: 20px;
  border-radius: 8px; 
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}


.india-heading {
  text-align: center;
  font-size: 20px; 
  margin-bottom: 15px;
  color:black;
  width: 100%;
  /* background-color: black; */
}


.payment-table {
  width: 100%;
  border-collapse: collapse; 
  margin-top: 10px;
}


.payment-table td {
  padding: 12px; 
  border: 1px solid #ddd; 
  font-size: 14px;
  text-align: left; 
}
.payment-table td strong {
  font-weight: bold;
}


@media (max-width: 768px) {
  .payment-details {
    width: 95%;
    padding: 15px;
  }

  .blue-heading h1,
  .heading h3,
  .india-heading {
    font-size: 18px;
  }

  .payment-table td {
    font-size: 12px;
    padding: 8px;
  }
}

.company-scrollable-container {
  max-height: 100px;  
  overflow-y: auto; 
  overflow-x: hidden; 
}

.company-scrollable-container::-webkit-scrollbar {
  width: 8px; 
}

.company-scrollable-container ::-webkit-scrollbar-thumb {
  background-color: #888; 
  border-radius: 10px; 
}

.company-scrollable-container ::-webkit-scrollbar-thumb:hover {
  background-color: #555; 
}

.scrollable-container {
  max-height: 360px;  
  overflow-y: auto;
  overflow-x: hidden; 
}


.scrollable-container::-webkit-scrollbar {
  width: 8px; 
}

.scrollable-container::-webkit-scrollbar-thumb {
  background-color: #888; 
  border-radius: 10px; 
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
  background-color: #555; 
}
.custom-password-input:focus { 
  border-color: #6b7280; 
  box-shadow: none;
}

#messages::-webkit-scrollbar {
  width: 8px; 
  /* height: 8px; */
}

#messages::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

#messages::-webkit-scrollbar-thumb {
  background-color: #3498db; 
  border-radius: 8px; 
  border: 2px solid #f1f1f1; 
}

#messages::-webkit-scrollbar-thumb:hover {
  background-color: #2980b9; 
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  animation: blink 1.5s infinite;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes blink {
  0%, 80%, 100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

/* Style for the <select> element */
.custom-select {
  padding: 8px;
  border: 1px solid gray;
  border-radius: 4px;
  outline: none;
  background-color: white;
  color: black;
}

/* Default background color for options */
.custom-select option {
  background-color: white; /* Default background */
  color: black; /* Default text color */
}

/* Background color for the selected option */
.custom-select option:checked {
  background-color: #2563eb; /* Blue */
  color: white; /* White text */
}

/* Hover effect for options */
.custom-select option:hover {
  background-color: #3b82f6; /* Light Blue */
  color: white;
}


